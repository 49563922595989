<template>
  <div
    ref="popupDialog"
    v-for="(item, index) in state.advertisementData"
    :key="item"
    :class="'popup-dialog-' + (index + 1)"
    style="position: fixed; width: 182px; z-index: 999"
    @mouseover="onMmouseover(index)"
    @mouseout="onMouseout(index)"
  >
    <div
      v-for="(child, childIndex) in item"
      :key="child"
      style="
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;
      "
      ref="advchildBox"
      @click="onOpenLink(child.LinkUrl)"
    >
      <el-image
        :src="$http.defaults.baseURL + child.FirstImg"
        style="width: 100%"
      />
      <text
        style="
          position: absolute;
          top: -10px;
          right: 0;
          font-size: 13px;
          cursor: pointer;
        "
        @click.stop="onCloseAdv(index + childIndex)"
        >关闭</text
      >
    </div>
  </div>
  <div style="width: 1200px; margin: 0 auto; padding-top: 20px">
    <el-carousel height="400px" class="carousel-box1">
      <el-carousel-item v-for="item in state.model.HomeCarousels" :key="item">
        <el-image
          :src="$http.defaults.baseURL + item"
          style="width: 100%; height: 100%"
        />
      </el-carousel-item>
    </el-carousel>
    <el-image
      :src="$http.defaults.baseURL + state.model.HomeImg"
      style="width: 100%; height: 100%; margin-top: 5px; cursor: pointer"
      @click="onHomeImgClick"
    />
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
      "
    >
      <div
        style="
          width: calc(50% - 10px);
          background-color: #fff;
          margin-top: 10px;
          height: 303px;
        "
      >
        <el-carousel
          height="303px"
          class="carousel-box2"
          :autoplay="false"
          v-if="state.carouselData?.length > 0"
        >
          <el-carousel-item
            v-for="item in state.carouselData"
            :key="item"
            @click="onNewsClick(item)"
          >
            <div :alt="item.Title" class="aeds">
              <el-image
                :src="$http.defaults.baseURL + item.FirstImg"
                style="width: 100%; height: 100%; cursor: pointer"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="index-new" v-for="item in state.moduleData" :key="item">
        <div class="index-new-title">
          <li
            style="
              height: 40px;
              border-bottom: 3px solid #3881da;
              display: flex;
              align-items: center;
            "
          >
            <el-image
              :src="$http.defaults.baseURL + item.Icon"
              style="height: 25px; width: 20px"
            />
            <text style="margin-left: 10px; font-size: 130%; color: #3881da">{{
              item.Title
            }}</text>
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              border-bottom: 1px solid #e2e2e2;
              height: 37px;
              line-height: 30px;
              cursor: pointer;
            "
            @click="$router.push('/main/m/' + item.MenuId)"
          >
            more>>
          </li>
        </div>
        <div class="index-new-content">
          <ul
            v-for="child in item.Newss"
            :key="child"
            class="flex align-center"
            @click="onNewsClick(child)"
          >
            <li
              style="
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #3881da;
              "
            ></li>
            <div style="flex: 1; overflow: hidden; padding-right: 20%">
              <li class="index-new-content-title">{{ child.Title }}</li>
            </div>
            <text>{{ $dayjs(child.PublishTime).format("YYYY-MM-DD") }}</text>
          </ul>
        </div>
      </div>
      <div
        style="
          width: calc(50% - 10px);
          background-color: #fff;
          margin-top: 10px;
          height: 303px;
          padding: 10px 40px;
          display: flex;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            overflow: hidden;
            display: flex;
            flex-direction: column;
            width: 30%;
            height: 100%;
            cursor: pointer;
          "
          @click="onOpenLink(item.LinkUrl)"
          v-for="(item, index) in state.imageData"
          :key="item"
          :style="{ marginLeft: index == 0 ? '' : '5%' }"
        >
          <ul
            style="
              height: 40px;
              border-bottom: 3px solid #3881da;
              display: flex;
              align-items: center;
            "
          >
            <el-image
              :src="$http.defaults.baseURL + item.Icon"
              style="height: 25px; width: 20px"
            />
            <text
              style="
                margin-left: 10px;
                font-size: 120%;
                color: #3881da;
                white-space: nowrap;
              "
              >{{ item.Name }}</text
            >
          </ul>
          <el-image
            :src="$http.defaults.baseURL + item.Image"
            style="width: 90%; flex: 1; margin-top: 20px; height: 100%"
          />
        </div>
      </div>
    </div>
    <div
      style="
        padding: 10px 20px;
        background-color: #fff;
        display: flex;
        align-items: center;
        margin-top: 20px;
      "
    >
      <div
        style="height: 235px; flex: 1.8; display: flex; flex-direction: column"
      >
        <div class="flex align-center">
          <li
            style="
              height: 40px;
              border-bottom: 3px solid #3881da;
              display: flex;
              align-items: center;
            "
          >
            <el-image
              :src="require('@/assets/images/6.png')"
              style="height: 25px; width: 20px"
            />
            <text style="margin-left: 10px; font-size: 130%; color: #3881da">
              特色业务
            </text>
          </li>
        </div>
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex: 1;
            overflow: hidden;
          "
        >
          <el-image
            :src="$http.defaults.baseURL + item.FirstImg"
            style="
              height: calc(50% - 5px);
              margin-top: 10px;
              width: calc(50% - 5px);
              cursor: pointer;
            "
            v-for="item in state.featureData"
            :key="item"
            @click="onMenuClick(item)"
          />
        </div>
      </div>
      <div
        style="
          height: 235px;
          flex: 1.5;
          display: flex;
          flex-direction: column;
          margin: 0 3vw;
        "
      >
        <div class="flex align-center">
          <li
            style="
              height: 40px;
              border-bottom: 3px solid #3881da;
              display: flex;
              align-items: center;
            "
          >
            <el-image
              :src="require('@/assets/images/6.png')"
              style="height: 25px; width: 20px"
            />
            <text style="margin-left: 10px; font-size: 130%; color: #3881da">
              出行服务
            </text>
          </li>
        </div>
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex: 1;
            overflow: hidden;
          "
        >
          <li
            style="
              width: calc(50% - 5px);
              display: flex;
              align-items: center;
              justify-content: center;
              height: calc(50% - 5px);
              padding-top: 20px;
              overflow: hidden;
              flex-direction: column;
              cursor: pointer;
            "
            v-for="item in state.serviceData"
            :key="item"
            @click="onMenuClick(item)"
          >
            <el-image
              :src="$http.defaults.baseURL + item.FirstImg"
              style="flex: 1"
              fit="contain"
            />
            <text style="color: #e87e14; margin-top: 3px">{{ item.Name }}</text>
          </li>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
        "
      >
        <text style="font-size: 120%"> 官方微信订阅号 </text>
        <el-image
          :src="$http.defaults.baseURL + state.model.WechatQrCode"
          style="width: 130px; height: 130px; margin-top: 10px"
        />
      </div>
    </div>
    <div
      class="flex align-center"
      style="padding: 20px; background-color: #fff; margin-top: 20px"
    >
      <li style="width: 40px; color: #3881da">友情链接</li>
      <div
        style="
          flex: 1;
          background-color: #f6f6f6;
          display: flex;
          align-items: center;
          padding: 20px 10px;
          margin-left: 30px;
        "
      >
        <div style="display: flex; overflow-x: auto; flex: 1">
          <el-image
            :src="$http.defaults.baseURL + item.FirstImg"
            v-for="item in state.linkData"
            :key="item"
            style="
              width: 112.55px;
              height: 40px;
              margin-right: 10px;
              cursor: pointer;
            "
            @click="onOpenLink(item.LinkUrl)"
          />
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          "
        >
          <ul class="flex align-center" style="white-space: nowrap">
            政府事业单位：<el-select
              v-model="state.gov"
              placeholder="--请选择--"
              size="small"
              style="width: 150px"
              @change="onLinkChange"
              value-key="Id"
            >
              <el-option
                v-for="item in state.govData"
                :key="item.Id"
                :value="item"
                :label="item.Title"
              />
            </el-select>
          </ul>
          <ul
            class="flex align-center"
            style="white-space: nowrap; margin-top: 5px"
          >
            其他网站链接：<el-select
              v-model="state.other"
              placeholder="--请选择--"
              size="small"
              style="width: 150px"
              @change="onLinkChange"
              value-key="Id"
            >
              <el-option
                v-for="item in state.otherData"
                :key="item.Id"
                :value="item"
                :label="item.Title"
              />
            </el-select>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, onUnmounted } from "vue";
import {
  home_getconfig,
  home_getadvertisement,
  home_getcarousel,
  home_getmodule,
  home_getonlyimage,
  home_getfeaturemenu,
  home_getservicemenu,
} from "@/http/api";
import http from "@/http/index";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const popupDialog = ref([]);
    const advchildBox = ref([]);
    const state = reactive({
      model: {},
      news: [],
      times: [],
    });
    onMounted(() => {
      home_getconfig().then((res) => {
        if (res.status == 200) state.model = res.data;
      });

      home_getadvertisement().then((res) => {
        if (res.status == 200) {
          const list = res.data.filter((w) => w.Type == 1);
          let newData = [];
          let ids = [];
          list.forEach((e) => {
            if (!ids.includes(e.Id)) {
              if (e.GroupKey) {
                let groups = list.filter((w) => w.GroupKey == e.GroupKey);
                newData.push(groups);
                ids = [...ids, ...groups.map((m) => m.Id)];
              } else {
                newData.push([e]);
                ids.push(e.Id);
              }
            }
          });

          state.advertisementData = newData;

          state.linkData = res.data.filter((w) => w.Type == 2);
          state.govData = res.data.filter((w) => w.Type == 3);
          state.otherData = res.data.filter((w) => w.Type == 4);

          setTimeout(() => {
            for (
              let index = 0;
              index < state.advertisementData.length;
              index++
            ) {
              initPopup(index);
            }
          }, 1000);
        }
      });

      home_getcarousel().then((res) => {
        if (res.status == 200) state.carouselData = res.data;
      });

      home_getmodule().then((res) => {
        if (res.status == 200) state.moduleData = res.data;
      });

      home_getonlyimage().then((res) => {
        if (res.status == 200) state.imageData = res.data;
      });

      home_getfeaturemenu().then((res) => {
        if (res.status == 200) state.featureData = res.data;
      });

      home_getservicemenu().then((res) => {
        if (res.status == 200) state.serviceData = res.data;
      });
    });

    const onLinkChange = (row) => {
      const url = row.LinkUrl;
      if (url.includes("http://") || url.includes("https://"))
        window.open(url, "_blank");
      else window.open("http://" + url, "_blank");
    };

    const onOnylImageClick = (item) => {
      window.open(http.defaults.baseURL + item.Image, "_blank");
    };

    onUnmounted(() => {
      for (let index = 0; index < state.advertisementData.length; index++) {
        clearInterval(state.times[index]);
      }
    });

    const onMmouseover = (index) => {
      if (state.times.length > index) {
        clearInterval(state.times[index]);
      }
    };
    const onMouseout = (index) => {
      if (state.times.length > index) {
        clearInterval(state.times[index]);
      }

      initPopup(index);
    };
    const initPopup = (index) => {
      let count = 30; //速度
      // let count = 500         //速度
      let stepX = 1;
      let stepY = 1;
      let pop = popupDialog.value[index];
      let popWidth = pop.offsetWidth;
      let popHeight = pop.offsetHeight;
      let clientw = document.body.clientWidth;
      let clienth = document.body.clientHeight;
      let x = parseInt(pop.getBoundingClientRect().left);
      let y = parseInt(pop.getBoundingClientRect().top);
      state.times[index] = setInterval(() => {
        let distenceX = clientw - x;
        let distenceY = clienth - y;
        if (distenceX - popWidth < 0 || distenceX > clientw) {
          stepX = -stepX;
        }
        if (distenceY - popHeight < 0 || distenceY > clienth) {
          stepY = -stepY;
        }
        x += stepX;
        y += stepY;
        changePos(pop, x, y);
      }, count);
    };
    const changePos = (pop, x, y) => {
      pop.style.left = x + "px";
      pop.style.top = y + "px";
    };

    const onCloseAdv = (index) => {
      advchildBox.value[index].style.display = "none";
    };

    const onOpenLink = (url) => {
      if (url) {
        if (url.includes("http://") || url.includes("https://"))
          window.open(url, "_blank");
        else window.open("http://" + url, "_blank");
      }
    };

    const onMenuClick = (menu) => {
      if (menu.Template == 7) {
        const url = menu.LinkUrl;
        if (url.includes("http://") || url.includes("https://"))
          window.open(url, "_blank");
        else window.open("http://" + url, "_blank");
      } else {
        router.push(`/main/m/${menu.Id}`);
      }
    };

    const onNewsClick = (news) => {
      if (news.IsLink) {
        const url = news.LinkUrl;
        if (url.includes("http://") || url.includes("https://"))
          window.open(url, "_blank");
        else window.open("http://" + url, "_blank");
      } else {
        router.push(`/main/c/${news.Id}`);
      }
    };

    const onHomeImgClick = () => {
      if (state.model.HomeImgMenuId) {
        router.push(`/main/m/${state.model.HomeImgMenuId}`);
      } else {
        if (state.model.HomeImgUrl) {
          const url = state.model.HomeImgUrl;
          if (url.includes("http://") || url.includes("https://"))
            window.open(url, "_blank");
          else window.open("http://" + url, "_blank");
        }
      }
    };

    return {
      state,
      onLinkChange,
      onOnylImageClick,
      onMmouseover,
      onMouseout,
      onCloseAdv,
      onOpenLink,
      popupDialog,
      advchildBox,
      onMenuClick,
      onNewsClick,
      onHomeImgClick,
    };
  },
};
</script>
<style lang="less" scoped>
.index-nav {
  background-color: #306eb2;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 8vw;
  height: 50px;

  & > .el-dropdown {
    padding: 0 10px;
    font-size: 120%;
    height: 100%;
    line-height: 50px;
    cursor: pointer;
    &:hover {
      border-top: 3px solid #ffa500;
    }
  }
}

.footer {
  background: #306eb2;
  width: 100%;
  margin-top: 34px;
  padding: 1vh 0;

  li {
    margin-top: 7px;
    text-align: center;
    color: #ffffff;
  }
}

.popup-dialog-1 {
  top: 0;
  left: 20px;
}

.popup-dialog-2 {
  top: 0;
  right: 20px;
}

.popup-dialog-3 {
  bottom: 0;
  left: 20px;
}

.popup-dialog-4 {
  bottom: 0;
  right: 20px;
}
</style>
